.modal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100vh;
  padding: 15px;
  background-color: $modal-overlay-c;
  visibility: hidden;
  opacity: 0;
  transition: $transition;

  &.is-open{
    visibility: visible;
    opacity: 1;
  }

  &-block{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 45px 15px 15px 15px;
    color: $primary-text-c;
    text-align: center;
    background-color: $primary-light-c;
  }

  &-content{
    padding: 5px 25px;
    overflow-y: auto;
    font-size: $text-fs-mob;
    letter-spacing: $slides-content-ls;

    @include md{
      font-size: $text-fs;
    }

    &-title{
      margin-bottom: 20px;
      font-weight: normal;
      text-transform: uppercase;

      @include md{
        margin-top: 65px;
      }
    }

    &-text{
      max-width: 425px;
      margin: 0 auto;
      line-height: $text-lh;
    }
  }

  &-close{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: $transition;

    &:hover{
      transform: scale(1.1);
    }
  }

  &-contacts{
    margin-top: 25px;

    &-link{
      text-decoration: none;
    }
  }
}