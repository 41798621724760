//Custom breakpoints
$breakpoint-xs: 375px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

//Custom mobile-first mixins
@mixin xs {
  @media all and (min-width: $breakpoint-xs) {
    @content;
  }
}

@mixin sm {
  @media all and (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md {
  @media all and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg {
  @media all and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl {
  @media all and (min-width: $breakpoint-xl) {
    @content;
  }
}
