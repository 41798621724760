.projects{
  background-color: $primary-light-c;
}

.slider{
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 0 15px;

  &-item{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -75%);
    opacity: 0;
    transition: $transition;
    pointer-events: none;

    @include md{
      width: 60%;
      max-width: 1000px;
      transform: translate(-50%, -65%);
    }

    &-img{
      max-height: 400px;
      object-fit: cover;
      overflow: hidden;

      @include md{
        max-height: 60vh;
      }
    }

    &-content{
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 480px;
      margin: 0 auto;
      font-size: $text-fs-mob;
      letter-spacing: $slides-content-ls;
      transform: translateY(100%);

      @include md{
        font-size: $text-fs;
      }
    }

    &.is-open{
      opacity: 1;
    }
  }

  &-prev,
  &-next{
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 3;
    width: 50%;
    content: "";
  }

  &-prev{
    left: 0;

    &:hover{
      cursor: url('../images/icons/arrow-cursor-left.png'), auto;
    }

  }
  &-next{
    left: 50%;

    &:hover{
      cursor: url('../images/icons/arrow-cursor-right.png'), auto;
    }
  }
}