.footer{
  color: $primary-text-c;
  background-color: $accent-bg-c;

  &-title{
    margin-bottom: 25px;
    font-weight: normal;
    font-size: $text-large-fs-mob;
    line-height: $text-lg-lh;
    letter-spacing: $authors-link-ls;

    @include md{
      font-size: $responsive-fs;
    }

    @include lg{
      margin-bottom: 66px;
      font-size: $text-large-fs;
    }
  }

  &-link{
    display: block;
    text-decoration: none;
    border-bottom: $border-bold-hover-black;
  }

  &-contacts{
    display: flex;
    align-items: center;
    padding: 10px 0;

    &-icon{
      width: 36px;
      height: 36px;
      margin: 0 8px;

      @include md{
        width: 50px;
        height: 50px;
      }
    }
  }
}