.authors{
  padding: 0 15px;
  background-color: $section-grey-c-mob;

  .authors-block + .authors-block{
    margin-top: 10px;
  }

  &-link{
    position: relative;
    color: $primary-text-c;
    font-weight: normal;
    font-size: $text-large-fs-mob;
    line-height: $text-lg-lh;
    letter-spacing: $authors-link-ls;
    text-transform: capitalize;
    text-decoration: none;

    &:hover{
      @include xs{
        border-bottom: $border-bold-hover-black;
      }
    }

    @include md{
      font-size: $responsive-fs;
    }

    @include lg{
      font-size: $text-large-fs;
    }
  }
}