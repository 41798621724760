// font vars
$fixed-nav-f: 'DejaVu Serif Condensed', serif;
$text-f: 'Univers Cyrillic', sans-serif;

// font sizes
$text-fs: 20px;
$text-large-fs: 122px;
$h1-fs: 123px;
$fixed-nav-fs: $text-fs;
$text-fs-mob: 14px;
$text-large-fs-mob: 48px;
$fixed-nav-fs-mob: $text-fs-mob;
$responsive-fs: 8vmax;

// line-height
$text-lg-lh: 105%;
$text-lh: 125%;

// letter-spacing
$nav-link-ls: 0.1em;
$authors-link-ls: -0.07em;
$slides-content-ls: -0.03em;

@font-face {
  font-weight: 400;
  font-family: 'DejaVu Serif Condensed';
  font-style: normal;
  src: local('DejaVu Serif Condensed'), local('DejaVuSerifCondensed'),
  url('../fonts/djSerifCondensed/dejavuserifcondensed.woff2') format('woff2')
  url('../fonts/djSerifCondensed/dejavuserifcondensed.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-weight: 400;
  font-family: 'Univers Cyrillic';
  font-style: normal;
  src: local('Univers Cyrillic 55 Roman'), local('Univers-Cyrillic-55-Roman'),
      url('../fonts/UniversLTCYR/UniversLTCYR-55Roman.woff2') format('woff2'),
      url('../fonts/UniversLTCYR/UniversLTCYR-55Roman.woff') format('woff');
  font-display: swap;
}
