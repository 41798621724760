// simple colors
$primary-light-c: #ffffff;
$primary-black-c: #000000;
$modal-overlay-c: rgba(0, 0, 0, 0.2);
$section-grey-c: #f7f7f7;
$section-grey-c-mob: #f4f4f4;
$accent-bg-c: #faff70;

// text colors
$primary-text-c: $primary-black-c;
$primary-text-light-c: $primary-light-c;
