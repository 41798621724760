.header{
  position: relative;
  min-height: 100vh;
  padding: 40px 0;
  text-align: center;
  background-color: $primary-black-c;

  .nav-fixed{

    &-item{
      position: fixed;
      z-index: 9;
      padding: 15px;
      color: $primary-text-light-c;
      font-size: $fixed-nav-fs-mob;
      font-family: $fixed-nav-f;
      letter-spacing: $nav-link-ls;
      text-transform: uppercase;
      mix-blend-mode: exclusion;

      &-top-left{
        top: 0;
        left: 0;
      }

      &-top-center{
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &-top-right{
        top: 10px;
        right: 0;
        transform: rotate(90deg);
        transform-origin: bottom;
      }

      &-bottom-left{
        bottom: -44px;
        left: 0px;
        transform: rotate(-90deg);
        transform-origin: top left;

        @include md{
          bottom: -82px;
        }
      }

      &-bottom-center{
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &-bottom-right{
        right: 0;
        bottom: 0;
      }

      @include md{
        padding: 30px;
        font-size: $fixed-nav-fs;
      }
    }

    &-link{
      display: block;
      text-decoration: none;

      &.is-active{
        position: relative;

        &::after{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border-bottom: $border-decorative;
          content: "";

          @include md{
            border-color: $primary-light-c;
            mix-blend-mode: difference;
          }
        }
      }
    }
  }

  h1{
    font-weight: 400;
    line-height: $text-lg-lh;
    letter-spacing: $authors-link-ls;
    @include md{
      color: $primary-text-c;
      font-size: $responsive-fs;
    }

    @include lg{
      font-size: $h1-fs;
    }
  }

  @include md{
    background-color: $primary-light-c;
  }
}
