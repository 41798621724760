.section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  transition: $transition;

  .service-list{
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .service-item + .service-item{
    margin-top: 10px;
  }

  .service-link{
    position: relative;
    color: $primary-text-c;
    font-size: $text-large-fs-mob;
    line-height: $text-lg-lh;
    letter-spacing: $authors-link-ls;
    text-decoration: none;

    @include md{
      color: $primary-text-light-c;
      font-size: $responsive-fs;
    }

    @include lg{
      font-size: $text-large-fs;
    }

    &:hover{
      border-bottom: $border-bold-hover-white;
    }
  }

  &.service{
    background-color: $primary-light-c;

    @include md{
      background-color: $primary-black-c;
    }
  }
}
