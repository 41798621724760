@import "../../node_modules/normalize.css/normalize.css";
*,
*:before,
*:after {
  box-sizing: border-box;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  scroll-behavior: smooth;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
}

body {
  position: relative;
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $primary-text-c;
  font-size: $text-fs-mob;
  font-family: $text-f;
  background-color: $primary-light-c;
  -webkit-overflow-scrolling: touch;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
}

li {
  list-style-type: none;
}

h1{
  color: $primary-text-light-c;
  font-size: $text-large-fs-mob;
  text-align: center;
}

.canvas-wrapper{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}

.container{
  padding: 0 15px;
}

.divider{
  display: block;
  height: 45px;
}

.no-scroll{
  overflow-y: hidden;
}